import {
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_GRID,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_PG_ONE_COLUMN,
  LAYOUT_SLIDER,
  LAYOUT_LIST,
  PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS,
} from '@wix/communities-blog-client-common';
import {
  HOVERING_BEHAVIOUR,
  OPTION_GALLERY_SIZE_TYPE,
  OVERLAY_ANIMATION,
  IMAGE_HOVER_ANIMATION,
  IMAGE_LOADING_MODE,
  CUBE_TYPE,
  TEXT_PLACEMENT,
  CALCULATE_TEXT_BOX_HEIGHT,
  TEXT_BOX_WIDTH_CALCULATION_OPTIONS,
} from '../../constants/pro-gallery-options';

const getLayoutImageRatio = (layout, options, cropType) => {
  if (`${cropType}` !== PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS.MAX) {
    return options.layoutImageRatio;
  }

  if (layout === LAYOUT_PG_SIDE_BY_SIDE) {
    return 4 / 3;
  }

  return 16 / 9;
};

export const createOptions = (layout, options, itemBorder) => {
  const commonGalleryOptions = {
    gallerySizeType: OPTION_GALLERY_SIZE_TYPE.PX,
    gallerySizePx: options.layoutPostSize,
    imageMargin: options.layoutSpacing,
    enableInfiniteScroll: true,
    allowTitle: true,
    allowContextMenu: true,
    overlayAnimation: OVERLAY_ANIMATION.FADE_IN,
    imageHoverAnimation: IMAGE_HOVER_ANIMATION.NO_EFFECT,
    imageLoadingMode: IMAGE_LOADING_MODE.BLUR,
    imageInfoType: 'ATTACHED_BACKGROUND',
    textsHorizontalPadding: -30,
    itemBorderWidth: itemBorder.width,
    itemBorderColor: itemBorder.color,
    isRTL: options.isRTL,
  };
  commonGalleryOptions.videoPlay = options.videoSettings.play;
  commonGalleryOptions.itemClick = 'nothing';
  commonGalleryOptions.videoSound = options.videoSettings.sound;
  commonGalleryOptions.showVideoPlayButton = options.videoSettings.showPlayButton;
  commonGalleryOptions.videoLoop = options.videoSettings.loop;
  commonGalleryOptions.videoSpeed = options.videoSettings.playbackSpeed;
  const cubeType =
    `${options.layoutImageCropType}` === PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS.MAX ? CUBE_TYPE.MAX : CUBE_TYPE.FILL;
  const cubeRatio = getLayoutImageRatio(layout, options, options.layoutImageCropType);

  switch (layout) {
    case LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        cubeImages: true,
        cubeType: CUBE_TYPE.FILL,
        cubeRatio,
        titlePlacement: TEXT_PLACEMENT.SHOW_ON_HOVER,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NO_CHANGE,
      };
    case LAYOUT_PG_SIDE_BY_SIDE:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        cubeType,
        cubeRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: options.textBoxAlignment,
        calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.PERCENT,
        textBoxWidthPercent: options.textBoxProportions,
        gridStyle: 1,
        numberOfImagesPerRow: 1,
      };
    case LAYOUT_PG_CARD_MEDIUM:
      return {
        ...commonGalleryOptions,
        galleryLayout: 1,
        isVertical: true,
        cubeImages: true,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case LAYOUT_PG_ONE_COLUMN:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        isVertical: true,
        cubeImages: true,
        cubeType: CUBE_TYPE.MIN,
        cubeRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case LAYOUT_SLIDER:
      return {
        ...commonGalleryOptions,
        galleryLayout: 5,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        showArrows: options.showArrows,
        slideshowInfoSize: options.layoutContentHeight,
        arrowsSize: options.arrowsSize,
        isAutoSlideshow: options.autoSlide,
        autoSlideshowInterval: options.pauseTime,
        arrowsPosition: options.arrowsPosition,
        arrowsColor: options.arrowsColor.value,
        slideshowLoop: options.loop,
      };
    case LAYOUT_LIST:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: [options.textBoxAlignment, 'SHOW_BELOW'],
        calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.PERCENT,
        textBoxWidthPercent: options.textBoxProportions,
        textBoxHeight: options.layoutContentHeight,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
      };
    case LAYOUT_PG_GRID:
    default:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
  }
};

export const toLayoutFixerStyles = (options) => ({
  collageAmount: 0,
  collageDensity: 0,
  cubeImages: options.cubeImages,
  cubeRatio: options.cubeRatio,
  cubeType: options.cubeType,
  externalInfoHeight: options.textBoxHeight,
  groupSize: 1,
  groupTypes: '1',
  imageMargin: options.imageMargin,
  isVertical: true,
  targetItemSize: options.gallerySizePx,
});

// link to pro-gallery playground: https://pro-gallery.surge.sh
